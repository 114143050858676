import React from 'react';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
// SEO
import Seo from '@components/utility/SEO';
// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
const BloodIcon = Loadable(() => import('@components/content/icons/Blood.jsx'));
const CalendarLargeIcon = Loadable(() =>
	import('@components/content/icons/CalendarLarge.jsx')
);
const Cta = Loadable(() => import('@components/content/cta/CTA'));
const FootIcon = Loadable(() => import('@components/content/icons/Foot.jsx'));
const ProteinIcon = Loadable(() => import('@components/content/icons/Protein.jsx'));
const TimesTwoIcon = Loadable(() => import('@components/content/icons/TimesTwo.jsx'));
const TimesFourIcon = Loadable(() => import('@components/content/icons/TimesFour.jsx'));
const UrineCupIcon = Loadable(() => import('@components/content/icons/UrineCup.jsx'));
const Video = Loadable(() => import('@components/content/video/Video.jsx'));

export const Head = () => (
	<>
		<script type='application/ld+json'
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "Sharing Your Lupus Nephritis Story | LUPKYNIS® (voclosporin)",
					"description": "Watch real people react to their lupus nephritis diagnosis and discover the common thread between each of their journeys.",
					"thumbnailUrl": "https://www.lupkynis.com/images/taking-lupkynis/watch-real-people-stories-poster-pt-1.png",
					"uploadDate": "2022-10-01",
					"embedUrl": "https://www.lupkynis.com/what-is-lupus-nephritis",
					"duration": "PT1M34S",
				})
			}}
		/>
	</>
)

const whatIsLupusNephritis = () => (
	<Layout addedClass='page--lupus-nephritis'>
		<Seo
			title='What Is Lupus Nephritis? | LUPKYNIS&reg; (voclosporin)'
			description='Lupus nephritis is a serious complication of lupus that might be more common than you think. See common signs, symptoms, and treatment options for lupus nephritis. See full Safety and Prescribing Information, and BOXED WARNING.'
			canonicalURL='https://www.lupkynis.com/what-is-lupus-nephritis'
		/>
		<Hero addedClass='bg-purple hero--string'>
			<div className='col-wrapper'>
				<div className='container'>
					<div className='hero__content'>
						<h1 className='heading heading--accent'>
							What is Lupus Nephritis?
						</h1>
						<h2>
							Lupus nephritis is a  <span>serious</span>and common
							complication of lupus
						</h2>
						<p>
							Lupus nephritis is an inflammation of the kidneys caused by
							Systemic Lupus Erythematosus (SLE). The kidneys are vital
							organs, and lupus nephritis is an irreversible and progressive
							condition that puts them at risk for long term complications.
							If left untreated, the inflammation caused by lupus nephritis
							destroys kidney tissue. This irreversible damage impairs
							kidney function and can lead to significant health problems,
							permanent kidney damage, and even kidney failure, known as End
							Stage Renal Disease (ESRD).
						</p>
					</div>
					<div className='hero__image'>
						<Image
							data={{
								desktopSrc: 'what-is-lupus-nephritis/hero.png',
								mobileSrc: 'what-is-lupus-nephritis/hero--mobile.png',
								altText: 'Image of Malisha, diagnosed 2020',
							}}
						/>
					</div>
				</div>
			</div>
		</Hero>
		<section id='Common' className='bg-white text-center'>
			<div className='container'>
				<div className='copy-block'>
					<h2>Lupus nephritis may be more common than you think</h2>
					<p>
						About 1 out of 2 people with lupus develop lupus nephritis over
						time. Compared to non-Hispanic White people, disease prevalence is
						roughly:
					</p>
				</div>
				<div className='col-wrapper text-left'>
					<div className='container'>
						<div className='icon-callout icon-callout--strong'>
							<div className='icon'>
								<TimesFourIcon />
							</div>
							<div>
								<p>
									<span className='sr-only'>4 times</span> higher for
									people of African and Asian descent
								</p>
							</div>
						</div>
						<div className='icon-callout icon-callout--strong'>
							<div className='icon'>
								<TimesTwoIcon />
							</div>
							<div>
								<p>
									<span className='sr-only'>2 times</span> higher for
									Hispanic and Native peoples
								</p>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className='copy-block'>
					<h3 className='heading heading--alt'>
						Common signs and symptoms of lupus nephritis are:
					</h3>
				</div>
				<div className='icon-bar col-wrapper bg-light-pink'>
					<div className='container'>
						<div className='icon-bar__item'>
							<div className='icon'>
								<FootIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Swelling</strong> (often in the feet, ankles,
									or legs)
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<UrineCupIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Foamy,</strong> frothy, or sudsy urine
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<BloodIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Blood in the urine</strong> (called hematuria)
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<ProteinIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Protein in the urine</strong> (called
									proteinuria)
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='copy-block'>
					<p>
						<strong>Symptoms can be subtle or inconsistent</strong> and often
						overlap with other diseases, so your doctor should also monitor
						you for signs of lupus nephritis with routine urine and blood
						tests.
					</p>
				</div>
			</div>
		</section>
		<section id='WatchVideo' className='col-wrapper bg-light-pink'>
			<div className='container'>
				<div className='copy-block'>
					<h2>Watch real people share their stories</h2>
					<p>
						See how Malisha, Suriya, and Jennifer reacted to their lupus
						nephritis diagnosis and how they discovered the common thread
						between each of their unique journeys.
					</p>
				</div>
				<Video
					src='/videos/the-common-thread-of-lupus-nephritis-part1.mp4'
					type='video/mp4'
					poster='/images/taking-lupkynis/watch-real-people-stories-poster-pt-1.png'
					title='Watch real people share their stories' controlsList='nodownload'
				/>
			</div>
		</section>
		<section id='RoutineTesting' className='bg-yellow'>
			<div className='container'>
				<div className='icon-callout'>
					<div className='icon'>
						<CalendarLargeIcon />
					</div>
					<div className='copy-block'>
						<p>
							Routine urine testing looks for protein in the urine (a common
							sign of lupus nephritis), which can help your doctor catch
							lupus nephritis early and start to determine a treatment plan.
						</p>
						<p>
							Maintaining routine urine testing is crucial to avoiding
							kidney failure, dialysis, or a transplant.
						</p>
					</div>
				</div>
			</div>
		</section>
		<section id='StandUp' className='col-wrapper bg-purple'>
			<div className='container'>
				<div className='copy-block'>
					<h2>Stand up to lupus nephritis</h2>
					<p>
						The goal of lupus nephritis treatment is to help stop the attack
						on the kidneys and help protect them from further permanent
						damage. Until recently, there were no FDA-approved treatments{' '}
						<i>specifically</i> for lupus nephritis.
					</p>
					<p>
						<strong>Commonly used treatments for lupus nephritis: </strong>
					</p>
					<p>
						<strong>Immunosuppressants:</strong> Used to treat aggressive or
						more severe lupus nephritis. These are powerful therapies that
						keep your immune system from damaging your kidneys.
					</p>
					<p>
						<strong>Steroids:</strong> Used to quickly reduce and control
						inflammation of the kidneys. Steroids can help, but relying on
						high-dose steroids over an extended period of time may increase
						the risk for adverse events, such as irreversible organ damage.
					</p>
					<p>
						<strong>Blood pressure medications:</strong> Used to lower blood
						pressure and may also slow the progression of kidney disease.
					</p>
					<p>
						<strong>Antimalarials: </strong>Often used to treat lupus and has
						also been shown to be beneficial in the treatment of lupus
						nephritis.
					</p>

					<p className='orange-bar'>
						Building a treatment plan around LUPKYNIS from the very beginning
						can raise the bar for how lupus nephritis is treated.
					</p>
				</div>
				<div className='image-block col-wrapper'>
					<div>
						<Image
							data={{
								desktopSrc: 'what-is-lupus-nephritis/warrior.png',
								mobileSrc: 'what-is-lupus-nephritis/warrior--mobile.png',
							}}
						/>
					</div>
					<Cta url='/what-is-lupkynis' isCircleArrow={true}>
						See how LUPKYNIS raises the bar
					</Cta>
				</div>
			</div>
		</section>
	</Layout>
);

export default whatIsLupusNephritis;
